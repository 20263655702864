<template>
  <v-card v-if="tempSettings != null" flat tile class="px-0 py-8">
    <!-- ### zipCodes ### -->
    <v-row no-gutters>
      <v-label class="mt-2">{{ texts.de.zipCodeRegions }}</v-label>
      <v-btn v-if="hasUserRight('manageBaseData')" :disabled="tempSettings.zipCodeRegions.includes(null)"
        @click="tempSettings.zipCodeRegions.push(null)" icon color="secondary">
        <v-icon>mdi-plus-box</v-icon>
      </v-btn>
    </v-row>
    <v-row no-gutters>
      <v-chip v-for="(z) in tempSettings.zipCodeRegions" :key="z" :close="hasUserRight('manageBaseData')"
        @click:close="removeZipCodeRegion(z)" class="mr-2 mb-1">
        <EditField style="width: 50px" class="mt-0 caption" v-if="z == null" @change="changeZipCodeRegion(z, $event)"
          :placeholder="$t('base.value')" hideDetails dense />
        <div v-else>{{ z }}</div>
      </v-chip>
      <span v-if="tempSettings.zipCodeRegions.length === 0">{{ $t('condAdmin.noRestriction') }}</span>
    </v-row>
    <v-row no-gutters class="mt-6 align-center">
      <v-label>{{ texts.de.leadDayIncrease }}</v-label>
      <EditField type="int" :value="tempSettings.leadDayIncrease" class="pl-2 mr-4 mt-0" style="max-width: 5rem"
        @change="changeLeadDays" placeholder="0" hideDetails dense :suffix="$t('base.days')" />
      <span>{{ '(' + texts.de.leadDaysTotal + (30 + tempSettings.leadDayIncrease) + ')' }}</span>
    </v-row>
  </v-card>
</template>

<script lang="js">
import condAdminMixin from '@/condAdmin//js/condAdminMixin.js';
import EditField from '@/common/EditField';

export default {
  name: 'ProviderSettingsYareto',
  components: {
    EditField
  },
  mixins: [condAdminMixin],
  props: {
    settings: { type: Object, required: true }
  },
  data () {
    return {
      tempSettings: null,
      texts: {
        de: {
          zipCodeRegions: 'Postleitzahlen-Eingrenzung',
          leadDayIncrease: 'Konditons-Vorlauftage erhöhen um',
          leadDaysTotal: 'gesamt '
        }
      }
    };
  },
  computed: {
  },
  watch: {
    settings () {
      this.init();
    }
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      this.tempSettings = JSON.parse(JSON.stringify(this.settings));
      if (this.tempSettings.zipCodeRegions == null) {
        this.tempSettings.zipCodeRegions = [];
      }
      if (this.tempSettings.leadDayIncrease == null) {
        this.tempSettings.leadDayIncrease = 0;
      }
    },
    removeZipCodeRegion (value) {
      let index = this.tempSettings.zipCodeRegions.indexOf(value);
      if (index > -1) {
        this.tempSettings.zipCodeRegions.splice(index, 1);
        this.emit();
      }
    },
    changeZipCodeRegion (oldValue, newValue) {
      let index = this.tempSettings.zipCodeRegions.indexOf(oldValue);
      if (index > -1) {
        this.tempSettings.zipCodeRegions[index] = newValue;
        this.emit();
      }
      this.tempSettings.zipCodeRegions = [...new Set(this.tempSettings.zipCodeRegions.sort((a, b) => { return a - b; }))];
    },
    changeLeadDays (v) {
      if (v !== 0) {
        this.tempSettings.leadDayIncrease = v;
      } else {
        delete this.tempSettings.leadDayIncrease;
      }
      this.emit();
    },
    emit () {
      this.$emit('update', this.tempSettings);
    }
  }
};
</script>

<style scoped>
</style>
